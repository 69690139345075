// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imp-menu-close-button {
  display: none;

  fill: black;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #00000060;
}

.imp-object-menu.imp-mobile.imp-active .imp-menu-close-button {
  display: block;
  position: absolute;
  left: -60px;
  top: 15px;
  width: 38px;
  height: 38px;
  text-align: center;
  line-height: 38px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imp-object-menu.imp-mobile.imp-active .imp-menu-close-button .imp-icon {
  width: 12px;
}
`, "",{"version":3,"sources":["webpack://./assets/UI/menu/closeButton.css"],"names":[],"mappings":"AAAA;EACE,aAAa;;EAEb,WAAW;EACX,iBAAiB;EACjB,yCAAyC;EACzC,2BAA2B;AAC7B;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".imp-menu-close-button {\n  display: none;\n\n  fill: black;\n  background: white;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);\n  border: 1px solid #00000060;\n}\n\n.imp-object-menu.imp-mobile.imp-active .imp-menu-close-button {\n  display: block;\n  position: absolute;\n  left: -60px;\n  top: 15px;\n  width: 38px;\n  height: 38px;\n  text-align: center;\n  line-height: 38px;\n  font-size: 18px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.imp-object-menu.imp-mobile.imp-active .imp-menu-close-button .imp-icon {\n  width: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

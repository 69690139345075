// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* fullscreen mode, button */
.imp-fullscreen-button {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  z-index: 3;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.imp-fullscreen-button .imp-icon {
  width: 16px;
  height: 16px;
}

/* Theme - Light */
.imp-ui-light .imp-fullscreen-button {
  fill: black;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border: 1px solid #00000060;
}

/* Theme - Dark */

.imp-ui-dark .imp-fullscreen-button {
  fill: white;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  border: 1px solid rgba(255, 255, 255, 0.25);
}
`, "",{"version":3,"sources":["webpack://./assets/UI/fullscreenButton.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,UAAU;EACV,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,2BAA2B;EAC3B,yBAAyB;EACzB,wBAAwB;EACxB,sBAAsB;EACtB,qBAAqB;EACrB,iBAAiB;;EAEjB,sBAAsB;EACtB,2BAA2B;EAC3B,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA,kBAAkB;AAClB;EACE,WAAW;EACX,iBAAiB;EACjB,yCAAyC;EACzC,2BAA2B;AAC7B;;AAEA,iBAAiB;;AAEjB;EACE,WAAW;EACX,8BAA8B;EAC9B,0BAA0B;EAC1B,2CAA2C;AAC7C","sourcesContent":["/* fullscreen mode, button */\n.imp-fullscreen-button {\n  position: relative;\n  width: 30px;\n  height: 30px;\n  border-radius: 5px;\n  z-index: 3;\n  margin-top: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n\n  box-sizing: border-box;\n  -moz-box-sizing: border-box;\n  -webkit-box-sizing: border-box;\n}\n\n.imp-fullscreen-button .imp-icon {\n  width: 16px;\n  height: 16px;\n}\n\n/* Theme - Light */\n.imp-ui-light .imp-fullscreen-button {\n  fill: black;\n  background: white;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);\n  border: 1px solid #00000060;\n}\n\n/* Theme - Dark */\n\n.imp-ui-dark .imp-fullscreen-button {\n  fill: white;\n  background: rgba(0, 0, 0, 0.5);\n  backdrop-filter: blur(2px);\n  border: 1px solid rgba(255, 255, 255, 0.25);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

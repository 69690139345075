// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body.imp-fullscreen-mode {
  overflow: hidden !important;
}

#imp-fullscreen-container {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  background: #0a0a0a;
}

#imp-fullscreen-container #imp-fullscreen-image-map {
  width: 100%;
  height: 100%;
}

#imp-fullscreen-container .imp-container {
  height: 100%;
}

#imp-fullscreen-container .imp-ui-wrap {
  width: 100%;
  height: 100%;
}

#imp-fullscreen-container .imp-canvas-wrap {
  position: inherit;
  z-index: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#imp-fullscreen-container .imp-canvas {
  overflow: visible;
}
`, "",{"version":3,"sources":["webpack://./assets/fullscreen.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B;;AAEA;EACE,eAAe;EACf,aAAa;EACb,WAAW;EACX,YAAY;EACZ,OAAO;EACP,MAAM;EACN,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,UAAU;EACV,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["body.imp-fullscreen-mode {\n  overflow: hidden !important;\n}\n\n#imp-fullscreen-container {\n  position: fixed;\n  z-index: 9999;\n  width: 100%;\n  height: 100%;\n  left: 0;\n  top: 0;\n  display: flex;\n  background: #0a0a0a;\n}\n\n#imp-fullscreen-container #imp-fullscreen-image-map {\n  width: 100%;\n  height: 100%;\n}\n\n#imp-fullscreen-container .imp-container {\n  height: 100%;\n}\n\n#imp-fullscreen-container .imp-ui-wrap {\n  width: 100%;\n  height: 100%;\n}\n\n#imp-fullscreen-container .imp-canvas-wrap {\n  position: inherit;\n  z-index: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n#imp-fullscreen-container .imp-canvas {\n  overflow: visible;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

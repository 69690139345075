// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imp-object-list {
  z-index: 1;
  overflow-y: auto;
  flex: 1;
}

.imp-object-list-artboard-container {
  transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.imp-object-list-artboard-container.imp-collapsed {
  height: 41px !important;
}
`, "",{"version":3,"sources":["webpack://./assets/UI/menu/list.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;EAChB,OAAO;AACT;;AAEA;EACE,mDAAmD;EACnD,wDAAwD;EACxD,2DAA2D;EAC3D,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".imp-object-list {\n  z-index: 1;\n  overflow-y: auto;\n  flex: 1;\n}\n\n.imp-object-list-artboard-container {\n  transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);\n  -moz-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);\n  -webkit-transition: all 0.25s cubic-bezier(0.55, 0, 0.1, 1);\n  overflow: hidden;\n}\n\n.imp-object-list-artboard-container.imp-collapsed {\n  height: 41px !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#debug {
  position: fixed;
  left: 0;
  top: 0;
  line-height: 32px;
  background: rgba(0,0,0,0.25);
  color: white;
  width: 100%;
  padding: 0 15px;
  transition: opacity 0.25s cubic-bezier(.55, 0, .1, 1);
  -moz-transition: opacity 0.25s cubic-bezier(.55, 0, .1, 1);
  -webkit-transition: opacity 0.25s cubic-bezier(.55, 0, .1, 1);
  z-index: 99999999;
  font-family: monospace;
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./assets/debug.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,OAAO;EACP,MAAM;EACN,iBAAiB;EACjB,4BAA4B;EAC5B,YAAY;EACZ,WAAW;EACX,eAAe;EACf,qDAAqD;EACrD,0DAA0D;EAC1D,6DAA6D;EAC7D,iBAAiB;EACjB,sBAAsB;EACtB,eAAe;AACjB","sourcesContent":["#debug {\n  position: fixed;\n  left: 0;\n  top: 0;\n  line-height: 32px;\n  background: rgba(0,0,0,0.25);\n  color: white;\n  width: 100%;\n  padding: 0 15px;\n  transition: opacity 0.25s cubic-bezier(.55, 0, .1, 1);\n  -moz-transition: opacity 0.25s cubic-bezier(.55, 0, .1, 1);\n  -webkit-transition: opacity 0.25s cubic-bezier(.55, 0, .1, 1);\n  z-index: 99999999;\n  font-family: monospace;\n  font-size: 18px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

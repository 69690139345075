// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imp-object {
  position: absolute;
  cursor: pointer;
  pointer-events: all;
  transition: all 0.15s cubic-bezier(0.55, 0, 0.1, 1);
  -moz-transition: all 0.15s cubic-bezier(0.55, 0, 0.1, 1);
  -webkit-transition: all 0.15s cubic-bezier(0.55, 0, 0.1, 1);

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.imp-object-static,
.imp-object-static * {
  cursor: default;
  pointer-events: none !important;
}

.imp-object-svg-single,
.imp-object-svg,
.imp-object-poly {
  pointer-events: none;
}
.imp-object-svg-single *,
.imp-object-svg *,
.imp-object-poly * {
  pointer-events: all;
}

/* image backgrounds */

.imp-object img {
  width: 100%;
}

.imp-image-backgrounds {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.imp-object-background-image {
  position: absolute;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

/* Glow */

.imp-glowing-objects .imp-object {
  animation: ObjectGlowAnimation linear 1.5s;
  animation-iteration-count: infinite;
}
`, "",{"version":3,"sources":["webpack://./assets/UI/objects/object.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,mDAAmD;EACnD,wDAAwD;EACxD,2DAA2D;;EAE3D,sBAAsB;EACtB,2BAA2B;EAC3B,8BAA8B;AAChC;;AAEA;;EAEE,eAAe;EACf,+BAA+B;AACjC;;AAEA;;;EAGE,oBAAoB;AACtB;AACA;;;EAGE,mBAAmB;AACrB;;AAEA,sBAAsB;;AAEtB;EACE,WAAW;AACb;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,kCAAkC;EAClC,wBAAwB;EACxB,4BAA4B;AAC9B;;AAEA,SAAS;;AAET;EACE,0CAA0C;EAC1C,mCAAmC;AACrC","sourcesContent":[".imp-object {\n  position: absolute;\n  cursor: pointer;\n  pointer-events: all;\n  transition: all 0.15s cubic-bezier(0.55, 0, 0.1, 1);\n  -moz-transition: all 0.15s cubic-bezier(0.55, 0, 0.1, 1);\n  -webkit-transition: all 0.15s cubic-bezier(0.55, 0, 0.1, 1);\n\n  box-sizing: border-box;\n  -moz-box-sizing: border-box;\n  -webkit-box-sizing: border-box;\n}\n\n.imp-object-static,\n.imp-object-static * {\n  cursor: default;\n  pointer-events: none !important;\n}\n\n.imp-object-svg-single,\n.imp-object-svg,\n.imp-object-poly {\n  pointer-events: none;\n}\n.imp-object-svg-single *,\n.imp-object-svg *,\n.imp-object-poly * {\n  pointer-events: all;\n}\n\n/* image backgrounds */\n\n.imp-object img {\n  width: 100%;\n}\n\n.imp-image-backgrounds {\n  z-index: 1;\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n}\n\n.imp-object-background-image {\n  position: absolute;\n  background-position: center center;\n  background-size: contain;\n  background-repeat: no-repeat;\n}\n\n/* Glow */\n\n.imp-glowing-objects .imp-object {\n  animation: ObjectGlowAnimation linear 1.5s;\n  animation-iteration-count: infinite;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* UI general */
.imp-ui-element {
  z-index: 3;
  cursor: pointer;

  border-radius: 5px;
  font-family: sans-serif;
  font-size: 14px;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}

.imp-ui-element:active {
  filter: brightness(0.8);
  -moz-filter: brightness(0.8);
  -webkit-filter: brightness(0.8);
}

.imp-ui-top-right {
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.imp-ui-bottom-right {
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.imp-ui-top-left {
  position: absolute;
  left: 20px;
  top: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.imp-ui-bottom-left {
  position: absolute;
  left: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
`, "",{"version":3,"sources":["webpack://./assets/UI/ui.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,UAAU;EACV,eAAe;;EAEf,kBAAkB;EAClB,uBAAuB;EACvB,eAAe;EACf,uBAAuB;EACvB,4BAA4B;EAC5B,+BAA+B;AACjC;;AAEA;EACE,uBAAuB;EACvB,4BAA4B;EAC5B,+BAA+B;AACjC;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB","sourcesContent":["/* UI general */\n.imp-ui-element {\n  z-index: 3;\n  cursor: pointer;\n\n  border-radius: 5px;\n  font-family: sans-serif;\n  font-size: 14px;\n  box-sizing: content-box;\n  -moz-box-sizing: content-box;\n  -webkit-box-sizing: content-box;\n}\n\n.imp-ui-element:active {\n  filter: brightness(0.8);\n  -moz-filter: brightness(0.8);\n  -webkit-filter: brightness(0.8);\n}\n\n.imp-ui-top-right {\n  position: absolute;\n  right: 20px;\n  top: 20px;\n  display: flex;\n  flex-direction: row;\n  align-items: flex-end;\n}\n\n.imp-ui-bottom-right {\n  position: absolute;\n  right: 20px;\n  bottom: 20px;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n}\n\n.imp-ui-top-left {\n  position: absolute;\n  left: 20px;\n  top: 20px;\n  display: flex;\n  flex-direction: row;\n  align-items: flex-end;\n}\n\n.imp-ui-bottom-left {\n  position: absolute;\n  left: 20px;\n  bottom: 20px;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

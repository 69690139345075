// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imp-object-list-item-artboard {
  position: relative;
  padding: 0;
  line-height: 40px;
  background: #f5f5f5;
  box-shadow: 0 2px 5px rgb(0 0 0 / 15%);
  z-index: 2;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #222;
  display: flex;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

.imp-object-list-item-artboard:hover {
  background: #eee;
}

.imp-object-list-item-artboard span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 20px;
  flex: 1;
}

.imp-object-list-item-artboard .imp-icon {
  position: absolute;
  top: 12px;
  right: 15px;
  width: 16px;
  height: 16px;
  pointer-events: none;
  z-index: 4;
  fill: #b7b7b7;
}

.imp-object-list-item-artboard.imp-collapsed-artboard-item .imp-icon {
  transform: rotateZ(90deg);
}
`, "",{"version":3,"sources":["webpack://./assets/UI/menu/itemArtboard.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,iBAAiB;EACjB,mBAAmB;EACnB,sCAAsC;EACtC,UAAU;EACV,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,aAAa;EACb,eAAe;EACf,yBAAyB;EACzB,sBAAsB;EACtB,qBAAqB;EACrB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,OAAO;AACT;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,WAAW;EACX,YAAY;EACZ,oBAAoB;EACpB,UAAU;EACV,aAAa;AACf;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".imp-object-list-item-artboard {\n  position: relative;\n  padding: 0;\n  line-height: 40px;\n  background: #f5f5f5;\n  box-shadow: 0 2px 5px rgb(0 0 0 / 15%);\n  z-index: 2;\n  font-family: sans-serif;\n  font-size: 12px;\n  font-weight: 700;\n  color: #222;\n  display: flex;\n  cursor: pointer;\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n  overflow: hidden;\n}\n\n.imp-object-list-item-artboard:hover {\n  background: #eee;\n}\n\n.imp-object-list-item-artboard span {\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n  padding: 0 20px;\n  flex: 1;\n}\n\n.imp-object-list-item-artboard .imp-icon {\n  position: absolute;\n  top: 12px;\n  right: 15px;\n  width: 16px;\n  height: 16px;\n  pointer-events: none;\n  z-index: 4;\n  fill: #b7b7b7;\n}\n\n.imp-object-list-item-artboard.imp-collapsed-artboard-item .imp-icon {\n  transform: rotateZ(90deg);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
